<template>
  <dash-page-new v-if="$store.state.auth.userType === 'superadmin'"
            title="Бізнес акаунти"
            subtitle="FineTrading"
            icon="mdi-briefcase-outline"
            no-side
            :side-navigation="navigationSelect"
            navigation-route="payments"
  >

    <template #default>
      <router-view></router-view>
    </template>

  </dash-page-new>
</template>

<script>

export default {
  name: "education",
  data() {
    return {
    }
  },
  computed : {
    navigationSelect() {
      return [
        { text : 'Всі'   , value : 'businesses'   , path :  ''   , icon : 'mdi-account-multiple' ,    } ,
        { text : 'Нові'    , value : 'new-clients'   , path :  'new-clients'   , icon : 'mdi-new-box' ,    } ,
        { text : 'Активні'  , value : 'month-income'   , path :  'month-income'   , icon : 'mdi-check-circle-outline' ,    } ,
        { text : 'Боржники'   , value : 'debt'   , path :  'debt'   , icon : 'mdi-target-account' ,    } ,
        { text : 'До видалення'  , value : 'deleted'   , path :  'deleted'   , icon : 'mdi-delete-outline' ,    } ,
        { text : 'Відключені'   , value : 'deactivated'   , path :  'deactivated'   , icon : 'mdi-cancel' ,    } ,

      ]
    },
  },
  methods : {
  },
}
</script>

<style scoped lang="scss">
</style>
